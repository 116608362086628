import { Row, Col } from 'reactstrap';
import Switch from 'react-switch';
import { Trans, useTranslation } from 'react-i18next';

function Agreement({ consent, setConsent, consentMessages, setСonsentMessages, tos, pp, esign }) {
  const { t } = useTranslation();

  const dwollaLink = 'https://www.dwolla.com/legal/tos';
  const privacyLink = 'https://www.dwolla.com/legal/privacy';

  return (
    <>
      <Row className="mt-3 mx-3">
        <Col xs={3} className="text-start pe-0">
          <Switch
            uncheckedIcon={false}
            checkedIcon={false}
            width={52}
            handleDiameter={23}
            onChange={() => setConsent(!consent)}
            checked={consent}
            onColor="#350569"
          />
        </Col>
        <Col xs={9} className="text-start ps-0">
          <p className="bo-consent-text">
            <Trans t={t} i18nKey="pages.signup_details_second.terms_of_service">
              By checking this box you agree to our <a href={tos}>Terms of Service</a>,
              <a href={pp}>Privacy Policy</a> and <a href={esign}>e-sign consent agreement</a> , as
              well as our partner <a href={dwollaLink}>Dwolla’s Terms of Service</a> and
              <a href={privacyLink}>Privacy Policy</a>.
            </Trans>
          </p>
        </Col>
      </Row>
      <Row className="mb-2 mx-3">
        <Col xs={3} className="text-start pe-0">
          <Switch
            uncheckedIcon={false}
            checkedIcon={false}
            width={52}
            handleDiameter={23}
            onChange={() => setСonsentMessages(!consentMessages)}
            checked={consentMessages}
            onColor="#350569"
          />
        </Col>
        <Col xs={9} className="text-start ps-0">
          <p className="bo-consent-text">
            <Trans t={t} i18nKey="pages.signup_details_second.consent_messages">
              By checking this box you agree to receive 2FA/OTP emails and/or text messages to your
              mobile phone. Standard text messages rates may apply.
            </Trans>
          </p>
        </Col>
      </Row>
    </>
  );
}

export default Agreement;
