import { useState, useEffect } from 'react';
import { Button, Container, Row, Col, Form, FormGroup, Input } from 'reactstrap';
import { Spinner } from '../../components/Spinner';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import NotAvailable from '../../components/NotAvailable';
import axios from 'axios';
import { useEnv } from '../../context/env-context';
import { makeUnauthorizedRequest } from '../../utils/makeRequest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useSessionData } from '../../hooks/useSessionData';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header';

export function Login() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const callbackUrl = searchParams.get('callbackUrl');

  const { apiOriginConsumer } = useEnv();
  const { sessionData, setSessionData, gotoError } = useSessionData();

  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [available, setAvailable] = useState({});
  const [validating, setValidating] = useState(true);
  const [pwdShow, setPwdShow] = useState(false);

  const login = () => {
    setSessionData(
      'consumer',
      JSON.stringify({
        email,
        password,
      }),
    );

    navigate(callbackUrl ? `/signup/signin?callbackUrl=${callbackUrl}` : '/signup/signin', {
      replace: true,
    });
  };

  const getAvailability = async () => {
    const URL = `${process.env.REACT_APP_API_ORIGIN_ORCHESTRATOR}/status`;

    try {
      const response = await axios.get(URL, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setAvailable(response.data);

      return response.data;
    } catch (error) {
      setAvailable({ status: 'down' });

      return { status: 'down' };
    }
  };

  const preValidateLogin = async () => {
    const config = {
      url: `${apiOriginConsumer}/pre_validate_login`,
      method: 'POST',
      data: JSON.stringify({}),
    };

    try {
      const data = await makeUnauthorizedRequest(config);

      return data;
    } catch (error) {
      setValidating(false);
      gotoError(error.message);

      return null;
    }
  };

  const validate = async () => {
    const pv = await preValidateLogin();

    if (!pv.ok) {
      gotoError(pv.reason);

      return false;
    }
    setSessionData('sessionId', pv.sessionId);
    setSessionData('isLogin', 'yes');

    return true;
  };

  const init = async () => {
    const av = await getAvailability();

    if (av.status !== 'down') {
      if (!sessionData().sessionId) {
        if (await validate()) {
          setSessionData(
            'consumer',
            JSON.stringify({
              email: '',
              password: '',
            }),
          );
          setValidating(false);
        }
      } else {
        setSessionData('isLogin', 'yes');
        setValidating(false);
      }
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeypress = e => {
    if (e.keyCode === 13) {
      login();
    }
  };

  if (available.status === 'down') {
    return <NotAvailable />;
  }

  if (validating) {
    return (
      <Container className="bo-mobile">
        <Row className="mt-5 align-items-center text-center mx-0">
          <Col>
            <Spinner visible text={t('common.hold_on')} contentColor="#FFFFFF" />
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container className="bo-mobile">
      <Header languageSwitcher />
      <div className="bo-rounded-container mt-5">
        <Row className="mt-4 mb-0 mx-3">
          <Col className="text-start">
            <p className="bo-text">{t('pages.login.welcome_back')}</p>
          </Col>
        </Row>
        <Row className="mt-1 mb-0 mx-3">
          <Col className="text-start">
            <p className="bo-text">
              {callbackUrl ? t('pages.login.please_login_2') : t('pages.login.please_login')}
            </p>
          </Col>
        </Row>
        <Row className="mt-3 mx-3">
          <Col className="text-center">
            <Form className="form">
              <FormGroup>
                <Input
                  className="bo-input"
                  type="text"
                  name="email"
                  id="email"
                  placeholder={t('pages.login.login_form.email_placeholder')}
                  value={email}
                  noValidate
                  onChange={e => setEmail(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <div className="position-relative">
                  <Input
                    className="bo-input"
                    type={pwdShow ? 'text' : 'password'}
                    name="password"
                    id="password"
                    placeholder={t('pages.login.login_form.password_placeholder')}
                    value={password}
                    noValidate
                    onChange={e => setPassword(e.target.value)}
                    onKeyUp={e => handleKeypress(e)}
                  />
                  <div className="bo-eye-wrapper" onClick={() => setPwdShow(!pwdShow)}>
                    <FontAwesomeIcon icon={pwdShow ? faEyeSlash : faEye} />
                  </div>
                </div>
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <Row className="mt-0 mx-3 mb-5">
          <Col className="text-center">
            <Link className="bo-link" to="/signup/reset">
              {t('pages.login.forgot_password')}
            </Link>
          </Col>
        </Row>
        <Row className="mt-5 mb-4 mx-3">
          <Col className="d-flex justify-content-center">
            <Button className="bo-button bo-w-120" onClick={login}>
              {t('pages.login.login')}
            </Button>
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default Login;
