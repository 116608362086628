import { useState } from 'react';
import { Button, Container, Row, Col, Form, FormGroup, Input } from 'reactstrap';
import { Spinner } from '../../components/Spinner';
import { Link } from 'react-router-dom';
import { useEnv } from '../../context/env-context';
import { makeUnauthorizedRequest } from '../../utils/makeRequest';
import { useSessionData } from '../../hooks/useSessionData';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header';

export function ResetPassword() {
  const { apiOriginConsumer } = useEnv();
  const [email, setEmail] = useState('');
  const { gotoError } = useSessionData();
  const [spinner, setSpinner] = useState(false);

  const { i18n, t } = useTranslation();

  const sendReset = async () => {
    setSpinner(true);
    const config = {
      url: `${apiOriginConsumer}/mobile_reset_password`,
      method: 'POST',
      data: JSON.stringify({ email, language: i18n.resolvedLanguage }),
    };

    try {
      await makeUnauthorizedRequest(config);
    } catch (error) {
      gotoError(error.message, '/signup/reset');
    } finally {
      setSpinner(false);
    }
  };

  return (
    <Spinner visible={spinner} text="" wrapperFlexGrow={1}>
      <Container className="bo-mobile">
        <Header languageSwitcher />
        <div className="bo-rounded-container mt-5">
          <Row className="mt-4 mb-0 mx-3">
            <Col className="text-start">
              <p className="bo-text">{t('pages.reset.enter_email')}</p>
            </Col>
          </Row>
          <Row className="mt-3 mx-3">
            <Col className="text-center">
              <Form className="form">
                <FormGroup>
                  <Input
                    className="bo-input"
                    type="text"
                    name="email"
                    id="email"
                    placeholder={t('pages.reset.email_placeholder')}
                    value={email}
                    noValidate
                    onChange={e => setEmail(e.target.value)}
                  />
                </FormGroup>
              </Form>
            </Col>
          </Row>
          <Row className="mt-3 mb-3 mx-3">
            <Col className="d-flex justify-content-center">
              <Button className="bo-button bo-w-200" onClick={sendReset}>
                {t('pages.reset.reset_email')}
              </Button>
            </Col>
          </Row>
          <Row className="mt-2 mx-3 mb-4">
            <Col className="text-center">
              <Link className="bo-link" to="/signup/login">
                {t('pages.reset.back_to_login')}
              </Link>
            </Col>
          </Row>
        </div>
      </Container>
    </Spinner>
  );
}

export default ResetPassword;
