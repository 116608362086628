import { Container, Row, Col, Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useSessionData } from '../../hooks/useSessionData';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header';
import FaqIcon from '../../components/icons/FaqIcon';
import SupportIcon from '../../components/icons/SupportIcon';
import PhoneIcon from '../../components/icons/PhoneIcon';

export function SignupSupport() {
  const navigate = useNavigate();
  const { sessionData } = useSessionData();

  const { t } = useTranslation();

  const cont = async () => navigate(-1);

  const faqLink = 'https://www.bridgeover.io/faq';
  const mailTo = `mailto:support@bridgeover.io?subject=Onboarding support request (Session ID: ${
    sessionData().sessionId
  })&body=Please describe your issue here...`;
  const phoneTo = 'tel:18004724230';

  return (
    <Container className="bo-mobile">
      <Header />
      <div className="bo-rounded-container mt-5">
        <Row className="mt-4 mx-3">
          <Col className="text-start">
            <p className="bo-text bo-larger-x2">{t('pages.support.need_help')}</p>
            <p className="bo-text">{t('pages.support.channels')}</p>
            <div>
              <div className="mb-3 d-flex">
                <div className="bo-w-30 me-2">
                  <FaqIcon />
                </div>
                <div>
                  <a className="bo-black-link" href={faqLink} target="_blank" rel="noreferrer">
                    {t('pages.support.faq_page')}
                  </a>
                </div>
              </div>
              <div className="mb-3 d-flex">
                <div className="bo-w-30 me-2">
                  <SupportIcon />
                </div>
                <div>
                  {t('pages.support.email_us')}
                  <a className="bo-link" href={mailTo}>
                    support@bridgeover.io
                  </a>
                </div>
              </div>
              <div className="mb-3 d-flex">
                <div className="bo-w-30 me-2">
                  <PhoneIcon />
                </div>
                <div>
                  {t('pages.support.call_us')}
                  <a className="bo-link" href={phoneTo}>
                    1-800-472-4230
                  </a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-5 mb-4 mx-3">
          <Col className="mt-5 text-center">
            <Button className="bo-button-outline px-5" onClick={cont}>
              {t('components.continue_button.back_btn_value')}
            </Button>
          </Col>
        </Row>
      </div>
    </Container>
  );
}
export default SignupSupport;
