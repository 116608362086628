import { Container, Row, Col } from 'reactstrap';
import { getMobileOperatingSystem } from '../../utils/os';
import { Link } from 'react-router-dom';
import { useSessionData } from '../../hooks/useSessionData';
import { Trans, useTranslation } from 'react-i18next';
import Header from '../../components/Header';

export function Summary() {
  const { sessionData } = useSessionData();
  const {
    consumer: { firstName },
  } = sessionData();

  const { t } = useTranslation();

  const getStore = () => {
    if (getMobileOperatingSystem() === 'iOS') {
      return (
        <Trans t={t} i18nKey="pages.summary.almost_there">
          You&lsquo;re almost there! The next step is to go ahead and download the Bridgeover app in
          the
          <a className="bo-link" href="https://apps.apple.com/us/app/bridgeover/id6449384243">
            {{ store: t('pages.summary.apple_store') }}
          </a>
          .
        </Trans>
      );
    }

    if (getMobileOperatingSystem() === 'Android') {
      return (
        <Trans t={t} i18nKey="pages.summary.almost_there">
          You&lsquo;re almost there! The next step is to go ahead and download the Bridgeover app in
          the
          <a
            className="bo-link"
            href="https://play.google.com/store/apps/details?id=io.bridgeover.mobile"
          >
            {{ store: t('pages.summary.google_play') }}
          </a>
          .
        </Trans>
      );
    }

    return (
      <Trans t={t} i18nKey="pages.summary.almost_there">
        You&lsquo;re almost there! The next step is to go ahead and download the Bridgeover app in
        the {{ store: t('pages.summary.not_mobile') }}.
      </Trans>
    );
  };

  return (
    <Container className="bo-mobile">
      <Header />
      <div className="bo-rounded-container mt-5">
        <Row className="mt-4 mx-3">
          <Col className="text-start">
            <p className="bo-text bo-larger-x2">{t('pages.summary.thank_you', { firstName })}</p>
          </Col>
        </Row>
        <Row className="mt-3 mb-0 mx-3">
          <Col className="text-start">
            <p className="bo-text">{getStore()}</p>
          </Col>
        </Row>
        <Row className="mt-3 mb-0 mx-3">
          <Col className="text-start">
            <p className="bo-text">{t('pages.summary.after_download')}</p>
          </Col>
        </Row>
        <Row className="mt-3 mb-0 mx-3">
          <Col className="text-start">
            <p className="bo-text">
              <Trans t={t} i18nKey="pages.summary.pending_account">
                Your account is currently in <strong>Pending</strong> status. You will be notified
                when it becomes <strong>Active</strong>.
              </Trans>
            </p>
            <p className="bo-text">{t('pages.summary.personal_settings')}</p>
            <p className="bo-text">
              <Trans t={t} i18nKey="pages.summary.to_contact">
                To contact Bridgeover support, please click
                <Link className="bo-link" to="/signup/support">
                  here
                </Link>
                .
              </Trans>
            </p>
          </Col>
        </Row>
        <Row className="mt-3 mb-4 mx-3">
          <Col className="text-start">
            {sessionData().skippedLink === 'true' ? (
              <>
                <p className="bo-text-bold">{t('pages.summary.keep_in_mind')}</p>
                <ul>
                  <li>{t('pages.summary.setup_bank')}</li>
                </ul>
              </>
            ) : (
              <p className="bo-text">{t('pages.summary.good_luck')}</p>
            )}
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default Summary;
