import { useTranslation } from 'react-i18next';
import { Row, Col, FormGroup, Input } from 'reactstrap';

function ProfileForm({ consumer, formErrors, handleChange, renderError }) {
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Col xs={6}>
          <FormGroup>
            <Input
              className={
                formErrors && formErrors.firstName ? 'bo-input bo-input-error' : 'bo-input'
              }
              type="text"
              name="firstName"
              id="firstName"
              placeholder={t('pages.signup_details_first.profile_form.first_name_placeholder')}
              value={consumer.firstName ? consumer.firstName : ''}
              noValidate
              onChange={e => handleChange(e)}
              disabled={consumer.tokenType === 'prospect'}
            />
            {'firstName' in formErrors ? renderError(formErrors.firstName) : ''}
          </FormGroup>
        </Col>
        <Col sx={6}>
          <FormGroup>
            <Input
              className={formErrors && formErrors.lastName ? 'bo-input bo-input-error' : 'bo-input'}
              type="text"
              name="lastName"
              id="lastName"
              placeholder={t('pages.signup_details_first.profile_form.last_name_placeholder')}
              value={consumer.lastName ? consumer.lastName : ''}
              noValidate
              onChange={e => handleChange(e)}
              disabled={consumer.tokenType === 'prospect'}
            />
            {'lastName' in formErrors ? renderError(formErrors.lastName) : ''}
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <Input
          className={formErrors && formErrors.email ? 'bo-input bo-input-error' : 'bo-input'}
          type="text"
          name="email"
          id="email"
          placeholder={t('pages.signup_details_first.profile_form.email_placeholder')}
          value={consumer.email ? consumer.email : ''}
          noValidate
          onChange={e => handleChange(e)}
        />
        {'email' in formErrors ? renderError(formErrors.email) : ''}
      </FormGroup>
    </>
  );
}

export default ProfileForm;
