import { Col, Row } from 'reactstrap';
import { useEnv } from '../context/env-context';
import { version } from '../version';

function EnvironmentFooter() {
  const { envName } = useEnv();

  return (
    <Row className="mt-3 ms-auto me-3">
      <Col>
        <p className="bo-footer">
          {`Signup v${version.major}.${version.minor}.${version.build} (${envName})`}
        </p>
      </Col>
    </Row>
  );
}

export default EnvironmentFooter;
