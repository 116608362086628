import { useTranslation } from 'react-i18next';

function WrongPassword() {
  const { t } = useTranslation();

  return (
    <>
      <strong>{t('components.wrong_password.description')}</strong>
      <ol>
        <li>{t('components.wrong_password.min_chars')}</li>
        <li>{t('components.wrong_password.min_types')}</li>
        <ul>
          <li>{t('components.wrong_password.lower_case')}</li>
          <li>{t('components.wrong_password.upper_case')}</li>
          <li>{t('components.wrong_password.numbers')}</li>
          <li>{t('components.wrong_password.special_chars')}</li>
        </ul>
        <li>{t('components.wrong_password.no_name_email')}</li>
      </ol>
    </>
  );
}

export default WrongPassword;
