import { FormGroup, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { PatternFormat } from 'react-number-format';

function LastFourSsn({ consumer, formErrors, handleChange, renderError }) {
  const { t } = useTranslation();

  return (
    <FormGroup>
      <PatternFormat
        name="last4ssn"
        id="last4ssn"
        format="####"
        mask="_"
        className={formErrors && formErrors.last4ssn ? 'bo-input bo-input-error' : 'bo-input'}
        placeholder={t('pages.signup_details_second.last_four_ssn.last_four_ssn_placeholder')}
        customInput={Input}
        value={consumer.last4ssn ? consumer.last4ssn : ''}
        onChange={e => handleChange(e)}
      />
      {'last4ssn' in formErrors ? renderError(formErrors.last4ssn) : ''}
    </FormGroup>
  );
}

export default LastFourSsn;
