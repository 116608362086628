import { Container, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header';

export function Default() {
  const { t } = useTranslation();

  return (
    <Container className="bo-mobile">
      <Header />
      <div className="bo-rounded-container mt-5">
        <Row className="mt-4 mx-3">
          <Col className="text-center">
            <p className="bo-header">{t('pages.default.smth_wrong')}</p>
          </Col>
        </Row>
        <Row className="mt-1 mb-4 mx-3">
          <Col className="text-center">
            <p className="bo-text">{t('pages.default.check_url')}</p>
          </Col>
        </Row>
      </div>
    </Container>
  );
}
export default Default;
