import { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useEnv } from '../../context/env-context';
import { Spinner } from '../../components/Spinner';
import AuthCode from 'react-auth-code-input';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useSessionData } from '../../hooks/useSessionData';
import ContinueButton from '../../components/ContinueButton';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header';
import { useOnMountUnsafe } from '../../hooks/useOnMountUnsafe';

export function SignupCode() {
  const { apiOriginConsumer } = useEnv();
  const [spinner, setSpinner] = useState(false);
  const [code, setCode] = useState(null);
  const [init, setInit] = useState(true);
  const [initData, setInitData] = useState(null);
  const [codeMountKey, setCodeMountKey] = useState(1);
  const navigate = useNavigate();
  const { sessionData, gotoError } = useSessionData();

  const { i18n, t } = useTranslation();

  const cont = async () => {
    try {
      setSpinner(true);
      // Send create consumer request
      const sd = sessionData();
      const response = await fetch(`${apiOriginConsumer}/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          signupToken: sd.signupToken,
          consumer: {
            ...sd.consumer,
            language: i18n.resolvedLanguage,
            phone: null,
            phoneCc: '1',
            phoneNdc: sd.consumer.phone.slice(1, 4),
            phoneSn: sd.consumer.phone.slice(6, 14).split('-').join(''),
          },
          sessionId: sd.sessionId,
          consent: sd.consent,
          consentMessages: sd.consentMessages,
          pp: sd.pp,
          tos: sd.tos,
          esign: sd.esign,
        }),
      });

      if (response.ok) {
        const responseData = await response.json();

        if (responseData.ok) {
          // vereification ok
          navigate('/signup/signin', { replace: true });
        } else {
          // verification error
          gotoError(responseData.reason, '/signup/page2');
        }
      } else {
        // request retured with http error code
        const err = await response.text();

        gotoError(JSON.parse(err).message, '/signup/page2');
      }
    } catch (error) {
      // request failed
      gotoError(error.message, '/signup/page2');
    }
  };

  const back = async () => navigate('/signup/page2', { replace: true });

  const initVerification = async (email, force = false) => {
    setInit(true);
    try {
      const response = await fetch(`${apiOriginConsumer}/init_email_verification`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sessionId: sessionData().sessionId,
          email,
          language: i18n.resolvedLanguage,
          force,
        }),
      });

      if (response.ok) {
        const responseData = await response.json();

        setInit(false);
        setInitData(responseData);
      } else {
        // request returned with http error code -> show error page
        const err = await response.text();

        setInit(false);
        setInitData(null);
        gotoError(JSON.parse(err).message, '/signup/page2');
      }
    } catch (error) {
      // request failed
      setInit(false);
      setInitData(null);
      gotoError(error.message, '/signup/page2');
    }
  };

  const validate = async (email, codeToValidate) => {
    setSpinner(true);
    try {
      const response = await fetch(`${apiOriginConsumer}/update_email_verification`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sessionId: sessionData().sessionId, email, code: codeToValidate }),
      });

      if (response.ok) {
        const responseData = await response.json();

        setSpinner(false);
        if (responseData.isVerified) {
          cont();
        } else {
          toast.error(t('pages.signup_code.email_varification_error'));
        }
      } else {
        setSpinner(false);
        const err = await response.text();

        gotoError(JSON.parse(err).message, '/signup/page2');
      }
    } catch (error) {
      // request failed
      setSpinner(false);
      gotoError(error.message, '/signup/page2');
    }
  };

  const resetCode = () => {
    // reset our data
    setCode(null);
    const resetKey = Math.random().toString().slice(0.8);

    setCodeMountKey(resetKey);
  };

  const resend = async () => {
    setSpinner(true);
    try {
      await initVerification(sessionData().consumer.email, true);
      toast.success(t('pages.signup_code.new_code_sent'));
    } catch (error) {
      toast.error(t('pages.signup_code.new_code_error'));
    }

    resetCode();
    setSpinner(false);
  };

  const handleChange = c => setCode(c);

  useOnMountUnsafe(() => {
    initVerification(sessionData().consumer.email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initData && initData.isVerified) {
      cont();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initData]);

  if (initData && !initData.isVerified) {
    return (
      <Spinner visible={spinner} text="" wrapperFlexGrow={1}>
        <Container className="bo-mobile">
          <Header languageSwitcher />
          <div className="bo-rounded-container mt-5">
            <Row className="mt-4 mb-2 mx-3">
              <Col className="text-center">
                {/* TODO: translation */}
                <p className="bo-larger-x3">Verification</p>
              </Col>
            </Row>
            <Row className="mb-2 mx-3">
              <Col className="text-center">
                <p className="bo-text">{t('pages.signup_code.code_sent')}</p>
              </Col>
            </Row>
            <Row className="mb-2 mx-3">
              <Col className="text-center">
                <p className="bo-text">{t('pages.signup_code.code_valid')}</p>
              </Col>
            </Row>
            <Row className="mt-4 mb-5 mx-0">
              <Col className="text-center px-0">
                <p className="bo-larger-x3">{t('pages.signup_code.code_enter')}</p>
                <AuthCode
                  key={codeMountKey}
                  containerClassName="bo-code-container"
                  inputClassName="bo-code-input"
                  allowedCharacters="numeric"
                  onChange={handleChange}
                  value={code}
                />
              </Col>
            </Row>
            <Row className="mb-4 mx-3">
              <Col className="text-center">
                <p className="bo-text">
                  {t('pages.signup_code.no_code')}
                  <a className="bo-link" onClick={() => resend()}>
                    {t('pages.signup_code.resend')}
                  </a>
                </p>
              </Col>
            </Row>
            <ContinueButton
              onClick={() => validate(sessionData().consumer.email, code)}
              onBack={back}
              disabled={!code || code.length !== 6}
            />
            <Row className="mb-2 mx-3" />
          </div>
        </Container>
      </Spinner>
    );
  }

  if (init) {
    return (
      <Container className="bo-mobile">
        <Row className="mt-5 align-items-center text-center mx-0">
          <Col>
            <Spinner visible={init} text={t('common.wait')} contentColor="#FFFFFF" />
          </Col>
        </Row>
      </Container>
    );
  }

  if (initData && initData.isVerified) {
    return (
      <Container className="bo-mobile">
        <Row className="mt-5 align-items-center text-center mx-0">
          <Col>
            <Spinner visible text={t('pages.signup_code.creating_user')} contentColor="#FFFFFF" />
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container className="bo-mobile">
      <Row className="mt-5 align-items-center text-center mx-0">
        <Col>
          <Spinner visible text={t('common.wait')} contentColor="#FFFFFF" />
        </Col>
      </Row>
    </Container>
  );
}

export default SignupCode;
