import { Container, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Header from './Header';

export function NotAvailable({ available }) {
  const { t } = useTranslation();

  if (available.message1) {
    return (
      <Container className="bo-mobile">
        <Header />
        <Row className="mt-3 mx-3">
          <Col className="text-start">
            <p className="bo-text text-center text-white">{available.message1}</p>
            {available.message2 && (
              <p className="bo-text text-center text-white">{available.message2}</p>
            )}
            {available.message3 && (
              <p className="bo-text text-center text-white">{available.message3}</p>
            )}
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container className="bo-mobile">
      <Header />
      <Row className="mt-3 mx-3">
        <Col className="text-start">
          <p className="bo-text text-center text-white">
            {t('components.not_available.message_first')}
          </p>
          <p className="bo-text text-center text-white">
            {t('components.not_available.message_second')}
          </p>
          <p className="bo-text text-center text-white">
            {t('components.not_available.message_third')}
          </p>
        </Col>
      </Row>
      <Row className="mt-3 ms-2 me-2">
        <Col className="text-center" />
      </Row>
    </Container>
  );
}
export default NotAvailable;
