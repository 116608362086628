import { Trans, useTranslation } from 'react-i18next';
import logo from '../assets/nameLogo.png';
import { Row, Col } from 'reactstrap';
import LanguageSwitcher from './LanguageSwitcher';

function Header({ welcomeText, personalDetails, languageSwitcher }) {
  const { t } = useTranslation();

  return (
    <div>
      {languageSwitcher && <LanguageSwitcher />}
      <Row className="mt-3 mx-0">
        <Col className="text-center mt-4">
          <img alt="logo" src={logo} className="name-logo" />
        </Col>
      </Row>
      {welcomeText && (
        <>
          <Row className="mt-2 mx-3">
            <Col className="text-center">
              <p className="bo-header text-white">{t('components.enter_details_header.title')}</p>
            </Col>
          </Row>
          <Row className="mb-2 mx-3">
            <Col className="text-center">
              <p className="bo-text text-white">
                {t('components.enter_details_header.signup_description')}
              </p>
            </Col>
          </Row>
        </>
      )}
      {personalDetails && (
        <Row className="mt-3 mb-2 mx-3">
          <Col className="text-center">
            <p className="bo-text text-white">
              <Trans i18nKey="components.enter_details_header.personal_details" t={t}>
                In order to verify your identity we need a few more personal details. Please
                complete the details below, accept the service <strong>terms and conditions</strong>
                and click &ldquo;Continue&rdquo;
              </Trans>
            </p>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default Header;
