function FaqIcon({ size = 26 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none">
      <rect width={size} height={size} fill="#350569" rx={size / 2} />
      <path
        fill="#51E5FF"
        // eslint-disable-next-line max-len
        d="M13 6.871a6.065 6.065 0 1 1-6.065 6.065A6.071 6.071 0 0 1 13 6.871Zm0-.83a6.895 6.895 0 1 0 0 13.79 6.895 6.895 0 0 0 0-13.79Z"
      />
      <path
        fill="#51E5FF"
        // eslint-disable-next-line max-len
        d="M13.137 6.871c.267 0 .738.468 1.14 1.493.474 1.208.735 2.832.735 4.572 0 1.74-.256 3.364-.735 4.572-.402 1.022-.871 1.493-1.14 1.493-.268 0-.738-.467-1.14-1.493-.472-1.207-.734-2.831-.734-4.572 0-1.74.255-3.364.734-4.572.404-1.027.875-1.493 1.14-1.493Zm0-.83c-1.494 0-2.704 3.088-2.704 6.895 0 3.808 1.21 6.895 2.704 6.895 1.494 0 2.705-3.087 2.705-6.895 0-3.807-1.21-6.894-2.705-6.894Z"
      />
      <path
        stroke="#51E5FF"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M7.083 15.09h12.11M7.083 10.78h12.11"
      />
    </svg>
  );
}

export default FaqIcon;
