import { useState, useEffect, useCallback } from 'react';
import { Button, Container, Row, Col } from 'reactstrap';
import { useEnv } from '../../context/env-context';
import { usePlaidLink } from 'react-plaid-link';
import { Spinner } from '../../components/Spinner';
import { useNavigate } from 'react-router-dom';
import { useSessionData } from '../../hooks/useSessionData';
import { Trans, useTranslation } from 'react-i18next';
import Header from '../../components/Header';

export function PlaidLink() {
  const navigate = useNavigate();
  const { apiOriginConsumer } = useEnv();
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const { sessionData, gotoError } = useSessionData();

  const { i18n, t } = useTranslation();

  const addSignupFunnel = async (eventType, eventDetails = {}) => {
    const sd = sessionData();

    try {
      const response = await fetch(`${apiOriginConsumer}/add_signup_funnel`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sd.signinToken}`,
        },
        body: JSON.stringify({
          sessionId: sd.sessionId,
          eventType,
          eventDetails,
        }),
      });

      if (!response.ok) {
        console.error(t('pages.plaidlink.signup_funnel_error'));
      }
    } catch (error) {
      console.error(t('pages.plaidlink.signup_funnel_error'), error);
    }
  };

  const skip = () => {
    addSignupFunnel('ACCOUNT_LINK_SKIPPED');
    window.sessionStorage.setItem('skippedLink', true);
    navigate('/signup/summary', { replace: true });
  };

  const cont = () => {
    window.sessionStorage.setItem('skippedLink', false);
    navigate('/signup/summary', { replace: true });
  };

  const getLinkToken = async () => {
    setSpinner(true);
    try {
      const response = await fetch(
        `${apiOriginConsumer}/link/get_link_token?language=${i18n.resolvedLanguage}`,
        {
          headers: {
            Authorization: `Bearer ${sessionData().signinToken}`,
          },
        },
      );

      if (response.ok) {
        const responseData = await response.json();

        setToken(responseData.link_token);
        setSpinner(false);
      } else {
        setSpinner(false);
        gotoError(t('pages.plaidlink.get_link_error'), '/signup/link');
      }
    } catch (error) {
      setSpinner(false);
      gotoError(t('pages.plaidlink.get_link_error'), '/signup/link');
    }
  };

  const onSuccess = useCallback(async publicToken => {
    const sd = sessionData();

    setLoading(true);
    try {
      const response = await fetch(`${apiOriginConsumer}/link/save_token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sd.signinToken}`,
        },
        body: JSON.stringify({
          publicToken,
          sessionId: sd.sessionId,
          language: i18n.resolvedLanguage,
        }),
      });

      if (response.ok) {
        window.sessionStorage.setItem('skippedLink', false);
        navigate('/signup/summary', { replace: true });
      } else {
        const err = await response.json();

        setLoading(false);
        gotoError(err.message, '/signup/link');
      }
    } catch {
      setLoading(false);
      gotoError(t('pages.plaidlink.save_token_error'), '/signup/link');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onExit = useCallback(async (err, metadata) => {
    if (err) {
      addSignupFunnel('ACCOUNT_LINK_FAILED', { err, metadata });
    } else {
      addSignupFunnel('ACCOUNT_LINK_CANCELED', { metadata });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEvent = useCallback(async (eventName, metadata) => {
    switch (eventName) {
      case 'OPEN': {
        setSpinner(false);
        break;
      }
      default:
        addSignupFunnel('ACCOUNT_LINK_EVENT', { eventName, metadata });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let isOauth = false;

  const config = {
    token,
    onSuccess,
    onExit,
    onEvent,
  };

  // For OAuth, configure the received redirect URI
  if (window.location.href.includes('?oauth_state_id=')) {
    config.receivedRedirectUri = window.location.href;
    isOauth = true;
  }

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (ready) {
      open();
    }
  }, [token, isOauth, ready, open]);

  if (loading) {
    return (
      <Container className="bo-mobile">
        <Row className="mt-5 align-items-center text-center mx-0">
          <Col>
            <Spinner visible contentColor="#FFFFFF" />
          </Col>
        </Row>
      </Container>
    );
  }

  const { consumer } = sessionData();
  const { firstName } = consumer;

  if (sessionData().isLogin === 'yes' && consumer.bankAccountStatus !== 'NOT_CONNECTED') {
    return (
      <Spinner visible={spinner} text="" wrapperFlexGrow={1}>
        <Container className="bo-mobile">
          <Header />
          <div className="bo-rounded-container mt-5">
            <Row className="mt-4 mx-3">
              <Col className="text-start">
                <p className="bo-text bo-larger">
                  <Trans t={t} i18nKey="pages.plaidlink.welcome_connected">
                    Welcome <strong>{{ firstName }}</strong>
                  </Trans>
                </p>
              </Col>
            </Row>
            <Row className="mt-2 mb-0 mx-3">
              <Col className="text-start">
                <p className="bo-text">{t('pages.plaidlink.connected')}</p>
              </Col>
            </Row>
            <Row className="mt-2 mx-3 mb-5">
              <Col className="text-center">
                <a href="#" className="bo-link" onClick={cont}>
                  {t('common.continue')}
                </a>
              </Col>
            </Row>
          </div>
        </Container>
      </Spinner>
    );
  }

  if (consumer) {
    return (
      <Spinner visible={spinner} text="" wrapperFlexGrow={1}>
        <Container className="bo-mobile">
          <Header />
          <div className="bo-rounded-container mt-5">
            <Row className="mt-4 mx-3">
              <Col className="text-start">
                <p className="bo-text bo-larger-x2">
                  <Trans t={t} i18nKey="pages.plaidlink.welcome_subscribe">
                    Welcome <strong>{{ firstName }}</strong>, you have successfully subscribed to
                    the Bridgeover service.
                  </Trans>
                </p>
              </Col>
            </Row>
            <Row className="mt-2 mb-0 mx-3">
              <Col className="text-start">
                <p className="bo-text">{t('pages.plaidlink.one_last_thing')}</p>
              </Col>
            </Row>
            <Row className="mt-0 mb-0 mx-3">
              <Col className="text-start">
                <p className="bo-text">{t('pages.plaidlink.please_tap')}</p>
              </Col>
            </Row>
            <Row className="mt-0 mb-0 mx-3">
              <Col className="text-start">
                <p className="bo-text">{t('pages.plaidlink.please_note')}</p>
              </Col>
            </Row>
            <ul className="bo-text mx-3">
              <li>{t('pages.plaidlink.terminate')}</li>
              <li>{t('pages.plaidlink.security_standards')}</li>
            </ul>
            <Row className="mt-3 mx-3">
              <Col className="text-center">
                <Button className="bo-button bo-w-150" onClick={getLinkToken}>
                  {t('pages.plaidlink.link_me')}
                </Button>
              </Col>
            </Row>
            <Row className="mt-5 mx-3 mb-4">
              <Col className="text-center">
                <a href="#" className="bo-link" onClick={skip}>
                  {t('pages.plaidlink.skip_for_now')}
                </a>
              </Col>
            </Row>
          </div>
        </Container>
      </Spinner>
    );
  }

  return (
    <Container className="bo-mobile">
      <Row className="mt-5 align-items-center text-center mx-0">
        <Col>
          <Spinner visible text={t('common.wait')} contentColor="#FFFFFF" />
        </Col>
      </Row>
    </Container>
  );
}

export default PlaidLink;
