import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'reactstrap';

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Col className="position-relative">
      <div className="bo-language-switcher">
        <button
          type="button"
          className="bo-language-switcher-dropdown"
          onClick={() => setIsOpen(!isOpen)}
        >
          <p className="bo-text-small">{i18n.resolvedLanguage === 'en' ? 'English' : 'Spanish'}</p>
        </button>
        {isOpen && (
          <div className="bo-language-switcher-options">
            <button
              type="button"
              className="bo-language-switcher-option"
              onClick={() => {
                i18n.changeLanguage('en');
                setIsOpen(false);
              }}
            >
              <p className="bo-text-small">English</p>
            </button>
            <button
              type="button"
              className="bo-language-switcher-option"
              onClick={() => {
                i18n.changeLanguage('es');
                setIsOpen(false);
              }}
            >
              <p className="bo-text-small">Spanish</p>
            </button>
          </div>
        )}
      </div>
    </Col>
  );
}

export default LanguageSwitcher;
