import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';

function Note() {
  const { t } = useTranslation();

  return (
    <Row className="mt-0 mb-2 mx-3">
      <Col className="text-start">
        <div className="bo-text-note">{t('pages.signup_details_first.bottom_note')}</div>
      </Col>
    </Row>
  );
}

export default Note;
