import LoadingOverlay from 'react-loading-overlay-ts';

LoadingOverlay.propTypes = undefined;

export function Spinner(props) {
  const { visible, text, wrapperFlexGrow, contentColor, children } = props;

  return (
    <LoadingOverlay
      active={visible}
      spinner
      fadeSpeed={100}
      text={text}
      styles={{
        wrapper: base => ({
          ...base,
          flexGrow: wrapperFlexGrow,
        }),
        overlay: base => ({
          ...base,
          color: 'rgba(0, 0, 0, 0.5)',
          position: 'absolute' /* or fixed */,
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          marginTop: '0px',
          marginLeft: '0px',
        }),
        spinner: base => ({
          ...base,
          width: '50px',
          '& svg circle': {
            stroke: contentColor || 'rgba(0, 0, 0, 0.5)',
          },
        }),
        content: base => ({
          ...base,
          color: contentColor,
        }),
      }}
    >
      {children}
    </LoadingOverlay>
  );
}
