import { useTranslation } from 'react-i18next';
import { Button, Row, Col } from 'reactstrap';

function ContinueButton({ onBack, onClick, disabled }) {
  const { t } = useTranslation();

  return (
    <Row className="my-3 mx-3">
      <Col
        className={`d-flex gap-3 ${onBack ? 'justify-content-between' : 'justify-content-center'}`}
      >
        {onBack && (
          <Button className="bo-button-outline w-50" onClick={onBack}>
            {t('components.continue_button.back_btn_value')}
          </Button>
        )}
        <Button className="bo-button w-50" onClick={onClick} disabled={disabled}>
          {t('components.continue_button.continue_btn_value')}
        </Button>
      </Col>
    </Row>
  );
}

export default ContinueButton;
