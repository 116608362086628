import { useState, useEffect } from 'react';
import { Container, Row, Col, Form } from 'reactstrap';
import { useEnv } from '../../context/env-context';
import { Spinner } from '../../components/Spinner';
import { useNavigate } from 'react-router-dom';
import {
  confirmPasswordValidation,
  emailValidation,
  mandatoryValidation,
  passwordValidation,
} from '../../utils/validation';
import { useSessionData } from '../../hooks/useSessionData';
import WrongPassword from './components/WrongPassword';
import Header from '../../components/Header';
import AggregatorForm from './components/AggregatorForm';
import ProfileForm from './components/ProfileForm';
import PasswordForm from './components/PasswordForm';
import Note from './components/Note';
import EnvironmentFooter from '../../components/EnvironmentFooter';
import SubscribedLink from './components/SubscibedLink';
import ContinueButton from '../../components/ContinueButton';
import AggregatorName from './components/AggregatorName';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

export function SignupDetails1() {
  const navigate = useNavigate();
  const { sessionData, gotoError } = useSessionData();
  const { apiOriginConsumer, apiOriginAggregator } = useEnv();

  const { i18n, t } = useTranslation();

  const [formErrors, setFormErrors] = useState({});
  const [spinner, setSpinner] = useState(false);

  const verifySignupEmail = async () => {
    const {
      sessionId,
      consumer: { email, firstName, lastName, tempAggregatorName },
    } = sessionData();

    try {
      setSpinner(true);
      const response = await fetch(`${apiOriginConsumer}/verify_signup_email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sessionId,
          email,
          language: i18n.resolvedLanguage,
          eventDetails: {
            firstName,
            lastName,
            email,
            tempAggregatorName,
          },
        }),
      });

      if (response.ok) {
        const responseData = await response.json();

        if (responseData.ok) {
          navigate('/signup/page2');
        } else {
          gotoError(responseData.reason, '/signup/page1');
        }
      } else {
        const err = await response.text();

        gotoError(JSON.parse(err).message, '/signup/page1');
      }
    } catch (error) {
      gotoError(error.message, '/signup/page1');
    }
  };

  const getSignupUrl = async () => {
    const {
      consumer: { email, firstName, lastName, tempAggregatorName },
    } = sessionData();

    try {
      setSpinner(true);
      const response = await axios(`${apiOriginAggregator}/get_signup_url`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          firstName,
          lastName,
          email,
          employerName: tempAggregatorName,
        },
      });

      if (response.data && response.data.aggregatorSignupUrl) {
        const url = response.data.aggregatorSignupUrl;
        const indexOf = url.indexOf('/signup/');
        const substring = url.substring(indexOf + 8);

        navigate(`/signup/${substring}`, { state: { email, firstName, lastName } });
      } else {
        navigate('/signup/no_employer');
      }
    } catch (error) {
      gotoError(error.message, '/signup/page1');
    } finally {
      setSpinner(false);
    }
  };

  const continueAction = async () => {
    const { genericSignup } = sessionData();

    if (genericSignup !== 'true') {
      verifySignupEmail();
    } else {
      getSignupUrl();
    }
  };

  function validate(name, value) {
    const { firstName, lastName, email, password } = sessionData().consumer;

    switch (name) {
      case 'tempAggregatorName':
      case 'firstName':
      case 'lastName':
        if (mandatoryValidation(value)) {
          setFormErrors({
            ...formErrors,
            [name]: t('pages.signup_details_first.form_errors.mandatory'),
          });
        } else {
          setFormErrors({ ...formErrors, [name]: null });
        }
        break;

      case 'email':
        if (emailValidation(value)) {
          setFormErrors({
            ...formErrors,
            [name]: t('pages.signup_details_first.form_errors.email'),
          });
        } else {
          setFormErrors({ ...formErrors, [name]: null });
        }
        break;

      case 'password':
        if (passwordValidation(value, firstName, lastName, email)) {
          setFormErrors({
            ...formErrors,
            [name]: <WrongPassword />,
          });
        } else {
          setFormErrors({ ...formErrors, [name]: null });
        }
        break;

      case 'confirmPassword':
        if (confirmPasswordValidation(value, password)) {
          setFormErrors({
            ...formErrors,
            [name]: t('pages.signup_details_first.form_errors.confirm_password'),
          });
        } else {
          setFormErrors({ ...formErrors, [name]: null });
        }
        break;
      default:
        break;
    }
  }

  function handleChange(e) {
    const { name, value } = e.target;

    window.sessionStorage.setItem(
      'consumer',
      JSON.stringify({ ...sessionData().consumer, [name]: value }),
    );

    validate(name, value);
  }

  useEffect(() => {
    window.sessionStorage.setItem('phase', 'details1');
  }, []);

  const mandatoryOk = () => {
    const {
      genericSignup,
      consumer: { firstName, lastName, email, password, confirmPassword, tempAggregatorName },
    } = sessionData();

    return (
      firstName &&
      lastName &&
      email &&
      (genericSignup !== 'true' ? password : true) &&
      (genericSignup !== 'true' ? confirmPassword === password : true) &&
      (genericSignup === 'true' ? tempAggregatorName : true)
    );
  };

  const renderError = text => <div className="bo-validation-error">{text}</div>;

  const { consumer, aggregator, genericSignup } = sessionData();

  return (
    <Spinner visible={spinner} text="" wrapperFlexGrow={1}>
      <Container className="bo-mobile">
        <Header languageSwitcher welcomeText />
        <div className="bo-rounded-container">
          {genericSignup === 'true' ? (
            <AggregatorForm
              genericSignup={genericSignup}
              consumer={consumer}
              aggregator={aggregator}
              formErrors={formErrors}
              handleChange={e => handleChange(e)}
              renderError={err => renderError(err)}
            />
          ) : (
            <AggregatorName aggregator={aggregator} />
          )}
          <Row className="mb-0 mx-3">
            <Col className="text-center">
              <Form className="form">
                <ProfileForm
                  consumer={consumer}
                  formErrors={formErrors}
                  handleChange={e => handleChange(e)}
                  renderError={err => renderError(err)}
                />
                {genericSignup !== 'true' && (
                  <PasswordForm
                    consumer={consumer}
                    formErrors={formErrors}
                    handleChange={e => handleChange(e)}
                    renderError={err => renderError(err)}
                  />
                )}
              </Form>
            </Col>
          </Row>
          {genericSignup !== 'true' && <Note />}
          <ContinueButton
            onClick={continueAction}
            disabled={
              // eslint-disable-next-line no-unused-vars
              Object.entries(formErrors || {}).filter(([_, value]) => value).length > 0 ||
              !mandatoryOk()
            }
          />
          {genericSignup !== 'true' && <SubscribedLink consumer={consumer} />}
        </div>
        <EnvironmentFooter />
      </Container>
    </Spinner>
  );
}

export default SignupDetails1;
