import { useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useEnv } from '../../context/env-context';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { makeRequest } from '../../utils/makeRequest';
import { useSessionData } from '../../hooks/useSessionData';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../../components/Spinner';

export function Signin() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { apiOriginConsumer, audience, clientId, domain } = useEnv();
  const { sessionData, setSessionData, gotoError } = useSessionData();

  const { i18n, t } = useTranslation();

  const getConsumerDetails = async () => {
    const config = {
      token: sessionData().signinToken,
      url: `${apiOriginConsumer}/signup_login`,
      method: 'GET',
      params: { sessionId: sessionData().sessionId },
    };

    try {
      const data = await makeRequest(config);

      return data;
    } catch (error) {
      gotoError(error, '/signup/login');

      return null;
    }
  };

  const signin = async () => {
    const { consumer } = sessionData();

    const callbackUrl = searchParams.get('callbackUrl');

    try {
      const details = {
        grant_type: 'password',
        username: consumer.email,
        password: consumer.password,
        audience,
        client_id: clientId,
      };

      let formBody = Object.keys(details).map(
        k => `${encodeURIComponent(k)}=${encodeURIComponent(details[k])}`,
      );

      formBody = formBody.join('&');

      const response = await fetch(`https://${domain}/oauth/token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formBody,
      });

      if (response.ok) {
        const responseData = await response.json();

        window.sessionStorage.setItem('signinToken', responseData.access_token);

        if (sessionData().isLogin === 'yes') {
          const det = await getConsumerDetails();

          if (det && det.language) {
            i18n.changeLanguage(det.language);
          }

          setSessionData(
            'consumer',
            JSON.stringify({
              ...consumer,
              firstName: det.firstName,
              lastName: det.lastName,
              bankAccountStatus: det.bankAccountStatus,
            }),
          );
        }

        navigate(callbackUrl || '/signup/link', { replace: true });
      } else {
        const err = await response.text();

        if (err) {
          gotoError(
            JSON.parse(err).error_description,
            callbackUrl ? `/signup/login?callbackUrl=${callbackUrl}` : '/signup/login',
          );
        }
      }
    } catch (error) {
      gotoError(
        error.message,
        callbackUrl ? `/signup/signin?callbackUrl=${callbackUrl}` : '/signup/signin',
      );
    }
  };

  useEffect(() => {
    signin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="bo-mobile">
      <Row className="mt-5 align-items-center text-center mx-0">
        <Col>
          <Spinner visible text={t('pages.signin.signing_in')} contentColor="#FFFFFF" />
        </Col>
      </Row>
    </Container>
  );
}

export default Signin;
