import React from 'react';

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
const apiOriginConsumer = process.env.REACT_APP_API_ORIGIN_CONSUMERS;
const apiOriginAggregator = process.env.REACT_APP_API_ORIGIN_AGGREGATORS;
const apiOriginMonitor = process.env.REACT_APP_API_ORIGIN_MONITOR;
const apiOriginScheduler = process.env.REACT_APP_API_ORIGIN_SCHEDILER;
const apiOriginFunding = process.env.REACT_APP_API_ORIGIN_FUNDING;
const apiOriginOrchestrator = process.env.REACT_APP_API_ORIGIN_ORCHESTRATOR;
const apiOriginNotifications = process.env.REACT_APP_API_ORIGIN_NOTIFICATIONS;
const envName = process.env.REACT_APP_ENV_NAME;

const isEnvValid =
  domain &&
  clientId &&
  audience &&
  apiOriginConsumer &&
  apiOriginAggregator &&
  apiOriginMonitor &&
  apiOriginScheduler &&
  apiOriginFunding &&
  apiOriginOrchestrator &&
  apiOriginNotifications &&
  envName;

if (!isEnvValid) {
  throw new Error('Missing environment variables.');
}

const dotenv = {
  domain,
  clientId,
  audience,
  apiOriginConsumer,
  apiOriginAggregator,
  apiOriginMonitor,
  apiOriginScheduler,
  apiOriginFunding,
  apiOriginOrchestrator,
  apiOriginNotifications,
  envName,
};

export const Env = dotenv;
export const EnvContext = React.createContext(dotenv);

export const useEnv = () => {
  const context = React.useContext(EnvContext);

  if (!context) {
    throw new Error('useEnv must be used within a EnvProvider');
  }

  return context;
};

export function EnvProvider(props) {
  return <EnvContext.Provider value={dotenv} {...props} />;
}
