function SupportIcon({ size = 26 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none">
      <rect width={size} height={size} fill="#350569" rx={size / 2} />
      <g clipPath="url(#a)">
        <path
          fill="#350569"
          // eslint-disable-next-line max-len
          d="M19.796 8.05H6.554c-.562 0-1.018.45-1.018 1.007v8.06c0 .556.456 1.007 1.018 1.007h13.242c.562 0 1.018-.45 1.018-1.007v-8.06c0-.557-.456-1.008-1.018-1.008Z"
        />
        <path
          fill="#51E5FF"
          // eslint-disable-next-line max-len
          d="M19.796 7.545H6.554c-.405 0-.793.16-1.08.443a1.503 1.503 0 0 0-.447 1.069v8.06c0 .4.16.785.447 1.068.287.284.675.443 1.08.443h13.242c.405 0 .794-.16 1.08-.443.287-.283.448-.668.448-1.068v-8.06c0-.401-.161-.785-.448-1.069a1.537 1.537 0 0 0-1.08-.443Zm-2.697 6.194a.512.512 0 0 0-.84.363.5.5 0 0 0 .122.347l3.203 3.171H6.766l3.203-3.17a.5.5 0 0 0-.026-.684.512.512 0 0 0-.692-.027l-3.206 3.169V9.077l5.462 4.204a2.752 2.752 0 0 0 3.336 0l5.462-4.204v7.83L17.1 13.74ZM7.02 8.553h12.31l-5.113 3.932a1.723 1.723 0 0 1-2.083 0L7.02 8.553Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M5.027 5.027h16.297v16.12H5.027z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SupportIcon;
