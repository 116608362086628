import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';

function AggregatorName({ aggregator }) {
  const { t } = useTranslation();
  const { name } = aggregator;

  return (
    <Row className="mb-2 mx-3">
      <Col className="mt-4 text-center">
        <p className="bo-text">{t('pages.signup_details_first.aggregator_name')}</p>
        <p className="bo-larger-x2">{name}</p>
      </Col>
    </Row>
  );
}

export default AggregatorName;
