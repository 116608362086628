import { useNavigate } from 'react-router-dom';

export const useSessionData = () => {
  const navigate = useNavigate();

  const sessionData = () => ({
    consumer: JSON.parse(window.sessionStorage.getItem('consumer')),
    aggregator: JSON.parse(window.sessionStorage.getItem('aggregator')),
    sessionId: window.sessionStorage.getItem('sessionId'),
    employeeIdMode: window.sessionStorage.getItem('employeeIdMode'),
    signupToken: window.sessionStorage.getItem('signupToken'),
    signinToken: window.sessionStorage.getItem('signinToken'),
    isLogin: window.sessionStorage.getItem('isLogin'),
    genericSignup: window.sessionStorage.getItem('genericSignup'),
    tos: window.sessionStorage.getItem('tos'),
    pp: window.sessionStorage.getItem('pp'),
    esign: window.sessionStorage.getItem('esign'),
    consent: window.sessionStorage.getItem('consent'),
    consentMessages: window.sessionStorage.getItem('consentMessages'),
    error: window.sessionStorage.getItem('error'),
    errorRetry:
      window.sessionStorage.getItem('errorRetry') === 'null'
        ? false
        : window.sessionStorage.getItem('errorRetry'),
    skippedLink: window.sessionStorage.getItem('skippedLink'),
  });

  const setSessionData = (key, value) => window.sessionStorage.setItem(key, value);

  const gotoError = (error, retry = null) => {
    window.sessionStorage.setItem('error', error);
    window.sessionStorage.setItem('errorRetry', retry);
    navigate('/signup/error');
  };

  return {
    sessionData,
    setSessionData,
    gotoError,
  };
};
