import { FormGroup, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { PatternFormat } from 'react-number-format';

function PhoneForm({ consumer, formErrors, handleChange, renderError }) {
  const { t } = useTranslation();

  return (
    <FormGroup>
      <PatternFormat
        name="phone"
        id="phone"
        format="(###) ###-####"
        mask="_"
        className={formErrors && formErrors.phone ? 'bo-input bo-input-error' : 'bo-input'}
        placeholder={t('pages.signup_details_second.phone_form.phone_placeholder')}
        customInput={Input}
        value={consumer.phone ? consumer.phone : ''}
        onChange={e => handleChange(e)}
      />
      {'phone' in formErrors ? renderError(formErrors.phone) : ''}
    </FormGroup>
  );
}

export default PhoneForm;
