import { Container, Row, Col } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useSessionData } from '../../hooks/useSessionData';
import { Trans, useTranslation } from 'react-i18next';
import Header from '../../components/Header';

export function Error() {
  const navigate = useNavigate();

  const { sessionData } = useSessionData();

  const { t } = useTranslation();

  return (
    <Container className="bo-mobile">
      <Header />
      <div className="bo-rounded-container mt-5">
        <Row className="mt-4 mx-3">
          <Col className="text-center">
            <p className="bo-larger-x2">{t('pages.error.something_went_wrong')}</p>
          </Col>
        </Row>
        <Row className="mt-1 mx-1">
          <Col className="text-center">
            <p className="bo-text-bold bo-text-red">{sessionData().error}</p>
          </Col>
        </Row>
        {/* <Row className="mt-1">
        <Col className="text-center">
          <p className="bo-text">
            <Trans t={t} i18nKey="pages.error.complete_signup">
              To complete your registration and linking your account, please
              <a
                href="#"
                className="bo-link"
                onClick={() => navigate('/signup/login', { replace: true })}
              >
                click here
              </a>
              to login.
            </Trans>
          </p>
        </Col>
      </Row> */}
        <Row className="mt-5 mb-4 mx-1">
          <Col className="text-center">
            {sessionData().errorRetry && (
              <a
                href="#"
                className="bo-link"
                onClick={() => navigate(sessionData().errorRetry, { replace: true })}
              >
                {t('pages.error.click_here_retry')}
              </a>
            )}
            <p className="bo-text mt-5">
              <Trans t={t} i18nKey="pages.error.contact_us">
                For Bridgeover support
                <Link className="bo-link" to="/signup/support">
                  contact us
                </Link>
              </Trans>
            </p>
          </Col>
        </Row>
      </div>
    </Container>
  );
}
export default Error;
