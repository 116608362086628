import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

function SubscribedLink({ consumer }) {
  const { t } = useTranslation();

  if (consumer.tokenType !== 'uniform') {
    return null;
  }

  return (
    <Row className="mt-3 mb-4 mx-3">
      <Col className="text-center">
        <Link className="bo-link bo-link-sm" to="/signup/login">
          {t('pages.signup_details_first.subscribed_link')}
        </Link>
      </Col>
    </Row>
  );
}

export default SubscribedLink;
