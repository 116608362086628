import moment from 'moment';
import { states } from '../constants/states';

function emailValidation(email) {
  const emailRegEx =
    // eslint-disable-next-line no-useless-escape, max-len
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (email && email.match(emailRegEx)) {
    return false;
  }

  return true;
}

function passwordValidation(password, firstName, lastName, email) {
  // return false; // remove - for dev only
  const password1 = /^(?=.*[A-Z])(?=.*[%~^\-_+.!@#$&*])(?=.*[0-9]).{8,}$/;
  const password2 = /^(?=.*[A-Z])(?=.*[%~^\-_+.!@#$&*])(?=.*[a-z]).{8,}$/;
  const password3 = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$/;
  const password4 = /^(?=.*[%~^\-_+.!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/;

  if (
    password &&
    (password.match(password1) ||
      password.match(password2) ||
      password.match(password3) ||
      password.match(password4)) &&
    !(
      password.toLocaleLowerCase().includes(firstName?.toLocaleLowerCase()) ||
      password.toLocaleLowerCase().includes(lastName?.toLocaleLowerCase()) ||
      password.toLocaleLowerCase().includes(email?.toLocaleLowerCase().split('@')[0])
    )
  ) {
    return false;
  }

  return true;
}

function confirmPasswordValidation(confirmPassword, password) {
  // return false; // remove - for dev only
  if ((confirmPassword && confirmPassword === password) || (!confirmPassword && !password)) {
    return false;
  }

  return true;
}

function phoneNumberValidation(phoneNumber) {
  const phoneRegex = /^\(([0-9]{3})\) ([0-9]{3})-([0-9]{4})$/;

  if (phoneNumber && phoneNumber.match(phoneRegex)) {
    return false;
  }

  return true;
}

function zipValidation(zip) {
  const zipRegex = /^([0-9]{5})$/;

  if (zip && zip.match(zipRegex)) {
    return false;
  }

  return true;
}

function dobValidation(dob) {
  return !moment(dob, 'MM/DD/YYYY', true).isValid();
}

function ageValidation(age) {
  return moment(age, 'MM/DD/YYYY').add(18, 'y') > moment();
}

function stateValidation(state) {
  return !states.includes(state.toUpperCase());
}

function mandatoryValidation(text) {
  return !text;
}

function fourDigitsValidation(num) {
  const numRegex = /^([0-9]{4})$/;

  if (num && num.match(numRegex)) {
    return false;
  }

  return true;
}

export {
  emailValidation,
  passwordValidation,
  confirmPasswordValidation,
  phoneNumberValidation,
  zipValidation,
  dobValidation,
  ageValidation,
  stateValidation,
  mandatoryValidation,
  fourDigitsValidation,
};
