import { Container, Row, Col, Input, Label } from 'reactstrap';
import { Spinner } from '../../components/Spinner';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSessionData } from '../../hooks/useSessionData';
import { useEnv } from '../../context/env-context';
import { makeUnauthorizedRequest } from '../../utils/makeRequest';
import { toast } from 'react-toastify';
import { Trans, useTranslation } from 'react-i18next';
import Header from '../../components/Header';

function NoEmplyer() {
  const { apiOriginAggregator } = useEnv();
  const { sessionData } = useSessionData();
  const [spinner, setSpinner] = useState(false);
  const [checked, setChecked] = useState(false);

  const { t } = useTranslation();

  const { tempAggregatorName, firstName, lastName, email } = sessionData().consumer;

  const setGenericNotificationConsent = async () => {
    setSpinner(true);

    const config = {
      url: `${apiOriginAggregator}/generic_notification_consent`,
      method: 'POST',
      data: JSON.stringify({ employerName: tempAggregatorName, firstName, lastName, email }),
    };

    try {
      await makeUnauthorizedRequest(config);

      setChecked(true);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const removeGenericNotificationConsent = async () => {
    setSpinner(true);

    const config = {
      url: `${apiOriginAggregator}/remove_generic_notification_consent`,
      method: 'POST',
      data: JSON.stringify({ email }),
    };

    try {
      await makeUnauthorizedRequest(config);

      setChecked(false);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const changeChecked = async () => {
    if (!checked) {
      setGenericNotificationConsent();
    } else {
      removeGenericNotificationConsent();
    }
  };

  return (
    <Spinner visible={spinner} text="" wrapperFlexGrow={1}>
      <Container className="bo-mobile">
        <Header />
        <div className="bo-rounded-container mt-4">
          <Row className="mt-4 mx-3">
            <Col className="text-start">
              <p className="bo-text bo-larger">{t('pages.no_employer.thank_you', { firstName })}</p>
            </Col>
          </Row>
          <Row className="mt-2 mx-3">
            <Col className="text-start">
              <p className="bo-text">{t('pages.no_employer.unfortunately')}</p>
            </Col>
          </Row>
          <Row className="mt-2 mx-3">
            <Col className="text-start">
              <p className="bo-text">{t('pages.no_employer.please_check')}</p>
            </Col>
          </Row>
          <Row className="mt-2 mx-3">
            <Col className="text-start">
              <p className="bo-text">{t('pages.no_employer.apologize')}</p>
            </Col>
          </Row>
          <Row className="mt-2 mx-3">
            <Col className="text-start">
              <p className="bo-text">
                <Trans t={t} i18nKey="pages.no_employer.contact_support">
                  If you think this is a mistake and your employer is a member, please contact
                  <Link className="bo-link" to="/signup/support">
                    Bridgeover support
                  </Link>
                </Trans>
              </p>
            </Col>
          </Row>
          <Row className="mt-2 mx-3">
            <Col className="text-start">
              <p className="bo-text">{t('pages.no_employer.bo_team')}</p>
            </Col>
          </Row>
          <Row className="mt-2 mb-4 mx-3">
            <Col className="text-start">
              <div className="d-flex">
                <Input type="checkbox" checked={checked} onChange={changeChecked} />
                <Label className="p-0 m-0 bo-text ms-3">
                  {t('pages.no_employer.please_notify')}
                </Label>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </Spinner>
  );
}

export default NoEmplyer;
