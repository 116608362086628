import { createContext, useMemo, useState } from 'react';

export const AuthContext = createContext({
  token: '',
  consumer: {},
  isAuthenticated: false,
  authenticate: () => {},
  logout: () => {},
});

function AuthContextProvider({ children }) {
  const [authToken, setAuthToken] = useState();
  const [authConsumer, setAuthConsumer] = useState();

  function authenticate(ret) {
    setAuthToken(ret.token);
    setAuthConsumer(ret.consumer);
  }

  function logout() {
    setAuthToken(null);
    setAuthConsumer(null);
  }

  const value = useMemo(
    () => ({
      token: authToken,
      consumer: authConsumer,
      isAuthenticated: !!(authToken && authConsumer),
      authenticate,
      logout,
    }),
    [authToken, authConsumer],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthContextProvider;
