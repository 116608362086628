import { useState, useEffect } from 'react';
import { Container, Row, Col, Form } from 'reactstrap';
import { useEnv } from '../../context/env-context';
import { makeUnauthorizedRequest } from '../../utils/makeRequest';
import { useNavigate } from 'react-router-dom';
import { useSessionData } from '../../hooks/useSessionData';
import Header from '../../components/Header';
import ContinueButton from '../../components/ContinueButton';
import {
  ageValidation,
  dobValidation,
  fourDigitsValidation,
  mandatoryValidation,
  phoneNumberValidation,
  stateValidation,
  zipValidation,
} from '../../utils/validation';
import Agreement from './components/Agreement';
import PhoneForm from './components/PhoneForm';
import AddressForm from './components/AddressForm';
import DateOfBirthForm from './components/DateOfBirthForm';
import EmployeeForm from './components/EmployeeForm';
import { useTranslation } from 'react-i18next';
import LastFourSsn from './components/LastFourSsn';

export function SignupDetails2() {
  const { apiOriginOrchestrator } = useEnv();
  const [formErrors, setFormErrors] = useState({});
  const [consent, setConsent] = useState(false);
  const [consentMessages, setСonsentMessages] = useState(false);
  const [pp, setPp] = useState(null);
  const [tos, setTos] = useState(null);
  const [esign, setEsign] = useState(null);
  const navigate = useNavigate();
  const { sessionData, gotoError } = useSessionData();

  const { t } = useTranslation();

  const cont = () => {
    window.sessionStorage.setItem('consent', consent);
    window.sessionStorage.setItem('consentMessages', consentMessages);
    navigate('/signup/code');
  };

  const back = () => navigate('/signup/page1', { replace: true });

  const getSetting = async key => {
    const config = {
      url: `${apiOriginOrchestrator}/get_setting`,
      method: 'GET',
      params: { key },
    };

    try {
      const data = await makeUnauthorizedRequest(config);

      if (key === 'privacy_policy') {
        setPp(data.value);
      } else if (key === 'terms_of_service') {
        setTos(data.value);
      } else if (key === 'e_signature') {
        setEsign(data.value);
      }
    } catch (error) {
      gotoError(error.message, '/signup/page2');
    }
  };

  useEffect(() => {
    window.sessionStorage.setItem('phase', 'details2');
    getSetting('privacy_policy');
    getSetting('terms_of_service');
    getSetting('e_signature');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function validate(name, value) {
    switch (name) {
      case 'phone':
        if (phoneNumberValidation(value)) {
          setFormErrors({
            ...formErrors,
            [name]: t('pages.signup_details_second.form_errors.phone'),
          });
        } else {
          setFormErrors({ ...formErrors, [name]: null });
        }
        break;
      case 'zip':
        if (zipValidation(value)) {
          setFormErrors({
            ...formErrors,
            [name]: t('pages.signup_details_second.form_errors.zip'),
          });
        } else {
          setFormErrors({ ...formErrors, [name]: null });
        }
        break;
      case 'dateOfBirth':
        if (dobValidation(value)) {
          setFormErrors({
            ...formErrors,
            [name]: t('pages.signup_details_second.form_errors.date_of_birth'),
          });
        } else if (ageValidation(value)) {
          setFormErrors({
            ...formErrors,
            [name]: t('pages.signup_details_second.form_errors.age'),
          });
        } else {
          setFormErrors({ ...formErrors, [name]: null });
        }
        break;
      case 'state':
        if (stateValidation(value)) {
          setFormErrors({
            ...formErrors,
            [name]: t('pages.signup_details_second.form_errors.state'),
          });
        } else {
          setFormErrors({ ...formErrors, [name]: null });
        }
        break;

      case 'last4ssn':
        if (fourDigitsValidation(value)) {
          setFormErrors({
            ...formErrors,
            [name]: t('pages.signup_details_second.form_errors.last_four_ssn'),
          });
        } else {
          setFormErrors({ ...formErrors, [name]: null });
        }
        break;
      case 'employeeId':
      case 'addressLine1':
      case 'city':
        if (mandatoryValidation(value)) {
          setFormErrors({
            ...formErrors,
            [name]: t('pages.signup_details_second.form_errors.mandatory'),
          });
        } else {
          setFormErrors({ ...formErrors, [name]: null });
        }
        break;
      case 'addressLine2':
        setFormErrors({ ...formErrors, [name]: null });
        break;
      default:
        break;
    }
  }

  function handleChange(e) {
    const { name, value } = e.target;

    window.sessionStorage.setItem(
      'consumer',
      JSON.stringify({
        ...sessionData().consumer,
        [name]: name === 'state' ? value.toUpperCase() : value,
      }),
    );

    validate(name, value);
  }

  const mandatoryOk = () => {
    const {
      employeeIdMode,
      consumer: { phone, addressLine1, city, state, zip, dateOfBirth, last4ssn, employeeId },
    } = sessionData();

    return (
      phone &&
      addressLine1 &&
      city &&
      state &&
      zip &&
      dateOfBirth &&
      last4ssn &&
      (employeeId || employeeIdMode === 'hide')
    );
  };

  const renderError = text => <div className="bo-validation-error">{text}</div>;

  const { consumer, employeeIdMode } = sessionData();

  return (
    <Container className="bo-mobile">
      <Header languageSwitcher personalDetails />
      <div className="bo-rounded-container">
        <Row className="mb-2 mx-3">
          <Col className="mt-4 text-center">
            <Form className="form">
              <PhoneForm
                consumer={consumer}
                formErrors={formErrors}
                handleChange={e => handleChange(e)}
                renderError={err => renderError(err)}
              />
              <AddressForm
                consumer={consumer}
                formErrors={formErrors}
                handleChange={e => handleChange(e)}
                renderError={err => renderError(err)}
              />
              <DateOfBirthForm
                consumer={consumer}
                formErrors={formErrors}
                handleChange={e => handleChange(e)}
                renderError={err => renderError(err)}
              />
              <LastFourSsn
                consumer={consumer}
                formErrors={formErrors}
                handleChange={e => handleChange(e)}
                renderError={err => renderError(err)}
              />
              <EmployeeForm
                employeeIdMode={employeeIdMode}
                consumer={consumer}
                formErrors={formErrors}
                handleChange={e => handleChange(e)}
                renderError={err => renderError(err)}
              />
            </Form>
          </Col>
        </Row>
        <Agreement
          consent={consent}
          setConsent={setConsent}
          consentMessages={consentMessages}
          setСonsentMessages={setСonsentMessages}
          tos={tos}
          pp={pp}
          esign={esign}
        />
        <ContinueButton
          onBack={back}
          onClick={cont}
          disabled={
            Object.entries(formErrors || {}).filter(([, value]) => value).length > 0 ||
            !consent ||
            !consentMessages ||
            !mandatoryOk()
          }
        />
        <Row className="mb-2 mx-3" />
      </div>
    </Container>
  );
}

export default SignupDetails2;
