import { FormGroup, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { PatternFormat } from 'react-number-format';

function DateOfBirthForm({ consumer, formErrors, handleChange, renderError }) {
  const { t } = useTranslation();

  return (
    <FormGroup>
      <PatternFormat
        name="dateOfBirth"
        id="dateOfBirth"
        format="##/##/####"
        mask="_"
        className={formErrors && formErrors.dateOfBirth ? 'bo-input bo-input-error' : 'bo-input'}
        placeholder={t('pages.signup_details_second.date_of_birth_form.date_placeholder')}
        customInput={Input}
        value={consumer.dateOfBirth ? consumer.dateOfBirth : ''}
        onChange={e => handleChange(e)}
      />
      {'dateOfBirth' in formErrors ? renderError(formErrors.dateOfBirth) : ''}
    </FormGroup>
  );
}

export default DateOfBirthForm;
