function PhoneIcon({ size = 26 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none">
      <rect width={size} height={size} fill="#350569" rx={size / 2} />
      <g clipPath="url(#a)">
        <path
          fill="#350569"
          // eslint-disable-next-line max-len
          d="m13.68 15.506.791-.358a.935.935 0 0 1 1.072.213l1.77 1.905a.935.935 0 0 1-.049 1.321l-.685.637a3.273 3.273 0 0 1-4.626-.17l-5.092-5.481a3.273 3.273 0 0 1 .17-4.626l.685-.637a.935.935 0 0 1 1.322.049l1.77 1.905a.935.935 0 0 1 .133 1.085l-.415.762a.935.935 0 0 0 .134 1.085l1.948 2.097a.936.936 0 0 0 1.072.213Z"
        />
        <path
          fill="#51E5FF"
          // eslint-disable-next-line max-len
          d="m17.582 18.93-.685.637a3.74 3.74 0 0 1-5.286-.195l-5.092-5.481a3.74 3.74 0 0 1 .194-5.287l.685-.636a1.403 1.403 0 0 1 1.983.073l1.77 1.904a1.403 1.403 0 0 1 .202 1.627l-.415.766a.468.468 0 0 0 .065.54l1.947 2.097a.468.468 0 0 0 .534.104l.793-.36a1.402 1.402 0 0 1 1.609.324l1.77 1.905a1.403 1.403 0 0 1-.073 1.982ZM7.35 9.29a2.806 2.806 0 0 0-.146 3.964l5.092 5.481a2.806 2.806 0 0 0 3.965.146l.685-.636a.468.468 0 0 0 .024-.661l-1.77-1.905a.469.469 0 0 0-.533-.104c-.974.443-.774.342-.793.36a1.403 1.403 0 0 1-1.609-.324l-1.948-2.097a1.403 1.403 0 0 1-.203-1.627l.415-.765a.468.468 0 0 0-.064-.54l-1.77-1.905a.468.468 0 0 0-.66-.024l-.685.636ZM15.364 13.224a.468.468 0 0 1-.786-.358 1.403 1.403 0 0 0-1.353-1.456.466.466 0 0 1-.448-.487.468.468 0 0 1 .486-.448c1.94.078 2.604 2.28 2.1 2.749Z"
        />
        <path
          fill="#51E5FF"
          // eslint-disable-next-line max-len
          d="M17.234 13.293a.468.468 0 0 1-.786-.359 3.276 3.276 0 0 0-3.154-3.395.468.468 0 1 1 .038-.934 4.209 4.209 0 0 1 4.052 4.36.469.469 0 0 1-.15.328Z"
        />
        <path
          fill="#51E5FF"
          // eslint-disable-next-line max-len
          d="M19.11 13.361a.467.467 0 0 1-.785-.358 5.143 5.143 0 0 0-4.952-5.33.468.468 0 1 1 .038-.935 6.079 6.079 0 0 1 5.85 6.296.468.468 0 0 1-.15.327Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M2.773 12.264 13.736 2.08 23.92 13.042 12.958 23.226z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PhoneIcon;
