import { useTranslation } from 'react-i18next';
import { FormGroup, Input } from 'reactstrap';

function EmployeeForm({ employeeIdMode, consumer, formErrors, handleChange, renderError }) {
  const { t } = useTranslation();

  if (employeeIdMode === 'hide') {
    return null;
  }

  return (
    <FormGroup>
      <Input
        className={formErrors && formErrors.employeeId ? 'bo-input bo-input-error' : 'bo-input'}
        type="text"
        name="employeeId"
        id="employeeId"
        placeholder={
          consumer.tokenType !== 'prospect'
            ? t('pages.signup_details_second.employee_form.employee_id_placeholder')
            : ''
        }
        value={consumer.employeeId ? consumer.employeeId : ''}
        noValidate
        onChange={e => handleChange(e)}
        disabled={consumer.tokenType === 'prospect'}
      />
      {'employeeId' in formErrors ? renderError(formErrors.employeeId) : ''}
    </FormGroup>
  );
}

export default EmployeeForm;
