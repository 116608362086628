// styles
import './App.css';
import './assets/bridgeover.css';
// fontawesome
import initFontAwesome from './utils/initFontAwesome';
// router
import MainRouter from './router/MainRouter';
// providers
import AuthContextProvider from './context/auth-context';
// show until translations are loaded
import { Suspense } from 'react';
import { Spinner } from './components/Spinner';
import { ToastContainer, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

initFontAwesome();

function App() {
  return (
    <AuthContextProvider>
      <Suspense fallback={<Spinner visible />}>
        <MainRouter />
        <ToastContainer
          position="top-center"
          autoClose={8000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
          theme="colored"
          transition={Bounce}
          className="bo-w-500"
        />
      </Suspense>
    </AuthContextProvider>
  );
}

export default App;
