import { useTranslation } from 'react-i18next';
import { PatternFormat } from 'react-number-format';
import { FormGroup, Input, Row, Col } from 'reactstrap';

function AddressForm({ consumer, formErrors, handleChange, renderError }) {
  const { t } = useTranslation();

  return (
    <>
      <FormGroup>
        <Input
          className={formErrors && formErrors.addressLine1 ? 'bo-input bo-input-error' : 'bo-input'}
          type="text"
          name="addressLine1"
          id="addressLine1"
          placeholder={t('pages.signup_details_second.address_form.line_first_placeholder')}
          value={consumer.addressLine1 ? consumer.addressLine1 : ''}
          noValidate
          onChange={e => handleChange(e)}
        />
        {'addressLine1' in formErrors ? renderError(formErrors.addressLine1) : ''}
      </FormGroup>
      <FormGroup>
        <Input
          className={formErrors && formErrors.addressLine2 ? 'bo-input bo-input-error' : 'bo-input'}
          type="text"
          name="addressLine2"
          id="addressLine2"
          placeholder={t('pages.signup_details_second.address_form.line_second_placeholder')}
          value={consumer.addressLine2 ? consumer.addressLine2 : ''}
          noValidate
          onChange={e => handleChange(e)}
        />
        {'addressLine2' in formErrors ? renderError(formErrors.addressLine2) : ''}
      </FormGroup>
      <Row>
        <Col xs={8}>
          <FormGroup>
            <Input
              className={formErrors && formErrors.city ? 'bo-input bo-input-error' : 'bo-input'}
              type="text"
              name="city"
              id="city"
              placeholder={t('pages.signup_details_second.address_form.city_placeholder')}
              value={consumer.city ? consumer.city : ''}
              noValidate
              onChange={e => handleChange(e)}
            />
            {'city' in formErrors ? renderError(formErrors.city) : ''}
          </FormGroup>
        </Col>
        <Col xs={4}>
          <FormGroup>
            <Input
              className={formErrors && formErrors.state ? 'bo-input bo-input-error' : 'bo-input'}
              type="text"
              name="state"
              id="state"
              placeholder={t('pages.signup_details_second.address_form.state_placeholder')}
              value={consumer.state ? consumer.state : ''}
              noValidate
              onChange={e => handleChange(e)}
              maxLength={2}
            />
            {'state' in formErrors ? renderError(formErrors.state) : ''}
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <PatternFormat
          name="zip"
          id="zip"
          format="#####"
          mask="_"
          className={formErrors && formErrors.zip ? 'bo-input bo-input-error' : 'bo-input'}
          placeholder={t('pages.signup_details_second.address_form.zip_placeholder')}
          customInput={Input}
          value={consumer.zip ? consumer.zip : ''}
          onChange={e => handleChange(e)}
        />
        {'zip' in formErrors ? renderError(formErrors.zip) : ''}
      </FormGroup>
    </>
  );
}

export default AddressForm;
