// navigation
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
// pages
import Login from '../pages/Login/Login';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import Signup from '../pages/Signup/Signup';
import SingnupDetails1 from '../pages/SignupDetails1/SignupDetails1';
import SingnupDetails2 from '../pages/SignupDetails2/SignupDetails2';
import SignupCode from '../pages/SignupCode/SignupCode';
import Error from '../pages/Error/Error';
import ShortUrl from '../pages/ShortUrl/ShortUrl';
import Default from '../pages/Default/Default';
import Signin from '../pages/Signin/Signin';
import PlaidLink from '../pages/PlaidLink/PlaidLink';
import Summary from '../pages/Summary/Summary';
import SignupSupport from '../pages/SignupSupport/SignupSupport';
import NoEmployer from '../pages/NoEmployer/NoEmployer';
import DeleteAccount from '../pages/DeleteAccount/DeleteAccount';
import DeleteAccountSuccess from '../pages/DeleteAccountSuccess/DeleteAccountSuccess';

function MainRouter() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <div id="app" className="d-flex flex-column h-100">
        <Routes>
          <Route path="/signup/no_employer" element={<NoEmployer />} />
          <Route path="/signup/page1" element={<SingnupDetails1 />} />
          <Route path="/signup/page2" element={<SingnupDetails2 />} />
          <Route path="/signup/code" element={<SignupCode />} />
          <Route path="/signup/signin" element={<Signin />} />
          <Route path="/signup/login" element={<Login />} />
          <Route path="/signup/reset" element={<ResetPassword />} />
          <Route path="/signup/link" element={<PlaidLink />} />
          <Route path="/signup/summary" element={<Summary />} />
          <Route path="/signup/error" element={<Error />} />
          <Route path="/signup/support" element={<SignupSupport />} />
          <Route path="/signup/:signup_token" element={<Signup />} />
          <Route path="/:short_url" element={<ShortUrl />} />
          <Route path="/delete_account" element={<DeleteAccount />} />
          <Route path="/delete_account_success" element={<DeleteAccountSuccess />} />
          <Route path="/" element={<Default />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default MainRouter;
