function getOperatingSystem(window) {
  if (window.navigator.appVersion.indexOf('Win') !== -1) {
    return 'Windows OS';
  }
  if (window.navigator.appVersion.indexOf('Mac') !== -1) {
    return 'MacOS';
  }
  if (window.navigator.appVersion.indexOf('X11') !== -1) {
    return 'UNIX OS';
  }
  if (window.navigator.appVersion.indexOf('Linux') !== -1) {
    return 'Linux OS';
  }

  return window.navigator.appVersion;
}

export const OS = window => getOperatingSystem(window); // <-- missing return

export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
};
